import React from 'react';
import EnhancedButton from './EnhancedButton';
import { useTranslation } from 'react-i18next';
import { Grid, SxProps } from '@mui/material';

export type Props = {
  onBack: () => void;
  onNext: () => void;
  backDisabled?: boolean;
  nextDisabled?: boolean;
  backLabel?: string;
  nextLabel?: string;
  containerClass?: any;
  sx?: SxProps;
};

/**
 * Displays Back and Next buttons for navigating Stepper controls
 * @param onBack
 * @param onNext
 * @param backDisabled
 * @param nextDisabled
 * @param backLabel
 * @param nextLabel
 * @param containerClass
 * @param sx
 * @constructor
 */
const BackNext = ({
  onBack,
  onNext,
  backDisabled,
  nextDisabled,
  backLabel,
  nextLabel,
  containerClass,
  sx,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={1} className={containerClass} sx={sx}>
      <Grid item>
        <EnhancedButton
          data-testid='EnhancedButtonOne'
          onClick={onBack}
          disabled={backDisabled}
          color={'secondary'}
        >
          {backLabel || t('back')}
        </EnhancedButton>
      </Grid>
      <Grid item>
        <EnhancedButton
          data-testid='EnhancedButtonTwo'
          onClick={onNext}
          disabled={nextDisabled}
        >
          {nextLabel || t('next')}
        </EnhancedButton>
      </Grid>
    </Grid>
  );
};

export default BackNext;
