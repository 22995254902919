import { createAction, createSlice } from '@reduxjs/toolkit';
import {
  STProducingLocationConfig,
  STProducingLocationConfigInfo,
  STMCellGuardrail,
  STMCellGuardrailInfo,
  STMCellMap,
  STMCellMapInfo,
  STSetting,
  STChangeLogDto,
  ProducingLocationOptionDto,
  STCapacityUtilizationPtoHoursTotalDto,
  STCapacityUtilizationEmployeesCountDto,
  STCapacityUtilizationShiftHoursTotalDto,
  STSalesOrderDto,
  STSalesOrderLineItemDto,
  STUnscheduledSalesOrderDto,
  STUnscheduledSalesOrderLineItemDto,
  STHardHoldDto,
  STCapacityUtilizationsAggregateDto,
  StLaborWhatIfInputAggregateDto,
  StLaborWhatIfOutputAggregateDto,
  StLaborWhatIfInputInfo,
  SalesOrderMain,
  SalesOrderExtra,
  SalesOrderLineItemPreview,
  SalesOrderLineItemDetail,
} from './types';
import { LoadingStatus } from '../../api/app.types';
import { createAsyncThunkWithError } from '../../redux/utils';
import {
  deleteProducingLocationConfig,
  createProducingLocationConfig,
  getProducingLocationConfigs,
  getProducingLocationConfigById,
  updateProducingLocationConfig,
  createMCellMap,
  getMCellMaps,
  getMCellMapById,
  updateMCellMap,
  deleteMCellMap,
  createMCellGuardrail,
  getMCellGuardrails,
  getMCellGuardrailById,
  updateMCellGuardrail,
  deleteMCellGuardrail,
  getSalesOrders,
  getSalesOrderLineItems,
  getUnscheduledSalesOrders,
  getUnscheduledSalesOrderLineItems,
  getHardHolds,
  getSettings,
  getSetting,
  setSetting,
  resetSettingToDefault,
  getChangeLogs,
  getAllChangeLogs,
  getChangeLog,
  addOrUpdateChangeLogs,
  deleteChangeLog,
  deleteChangeLogs,
  commitChangeLogsToICIM,
  deleteChangeLogBulk,
  getCapacityUtilizationEmployeeCounts,
  getCapacityUtilizationPtoHoursTotals,
  getCapacityUtilizationShiftHoursTotals,
  getProducingLocationOptions,
  triggerManualRefresh,
  isRefreshRunning,
  getCapacityUtilizationsAggregate,
  downloadMCellMapData,
  downloadMCellGuardrailData,
  resetLaborWhatIfs,
  addLaborWhatIfShiftHoursPerEmployeeInput,
  addLaborWhatIfEmployeeCountInput,
  addLaborWhatIfPtoHoursInput,
  calculateLaborWhatIfs,
  getLaborWhatIfInputAggregate,
  getLaborWhatIfOutputAggregate,
  getSalesOrderMain,
  getSalesOrderExtra,
  getSalesOrderLineItemsDetail,
  getSalesOrderLineItemsPreview,
  searchOrderNumber,
} from './api';
import { RootState } from '../../store';
import { saveBlobToFile } from '../../utils/file';

export type SchedulingToolState = {
  schedulingToolProducingLocationConfigs: STProducingLocationConfig[];
  schedulingToolProducingLocationConfigsStatus: LoadingStatus;
  schedulingToolProducingLocationOptions: ProducingLocationOptionDto[];
  schedulingToolProducingLocationOptionsStatus: LoadingStatus;
  schedulingToolMCellMaps: STMCellMap[];
  schedulingToolMCellMapsStatus: LoadingStatus;
  schedulingToolGuardrails: STMCellGuardrail[];
  schedulingToolGuardrailsStatus: LoadingStatus;
  schedulingToolSalesOrders: STSalesOrderDto[];
  schedulingToolSalesOrdersStatus: LoadingStatus;
  schedulingToolSalesOrderLineItems: STSalesOrderLineItemDto[];
  schedulingToolSalesOrderLineItemsStatus: LoadingStatus;
  schedulingToolUnscheduledSalesOrders: STUnscheduledSalesOrderDto[];
  schedulingToolUnscheduledSalesOrdersStatus: LoadingStatus;
  schedulingToolUnscheduledSalesOrderLineItems: STUnscheduledSalesOrderLineItemDto[];
  schedulingToolUnscheduledSalesOrderLineItemsStatus: LoadingStatus;
  schedulingToolHardHolds: STHardHoldDto[];
  schedulingToolHardHoldsStatus: LoadingStatus;
  schedulingToolSettings: STSetting[];
  schedulingToolSettingsStatus: LoadingStatus;
  schedulingToolChangeLogs: STChangeLogDto[];
  schedulingTooChangeLogsStatus: LoadingStatus;
  capacityUtilizationEmployeeCounts: STCapacityUtilizationEmployeesCountDto[];
  capacityUtilizationEmployeeCountsStatus: LoadingStatus;
  capacityUtilizationPtoHoursTotals: STCapacityUtilizationPtoHoursTotalDto[];
  capacityUtilizationPtoHoursTotalsStatus: LoadingStatus;
  capacityUtilizationShiftHoursTotals: STCapacityUtilizationShiftHoursTotalDto[];
  capacityUtilizationShiftHoursTotalsStatus: LoadingStatus;
  schedulingToolRefreshRunning: Boolean;
  schedulingToolRefreshRunningStatus: LoadingStatus;
  capacityUtilizationsAggregate: STCapacityUtilizationsAggregateDto;
  capacityUtilizationsAggregateStatus: LoadingStatus;
  downloadMCellMapDataStatus: LoadingStatus;
  downloadMCellGuardrailDataStatus: LoadingStatus;
  laborWhatIfInputResetStatus: LoadingStatus;
  laborWhatIfOutputCalculateStatus: LoadingStatus;
  laborWhatIfInputStatus: LoadingStatus;
  laborWhatIfInputAggregate: StLaborWhatIfInputAggregateDto;
  laborWhatIfInputAggregateStatus: LoadingStatus;
  laborWhatIfOutputAggregate: StLaborWhatIfOutputAggregateDto;
  laborWhatIfOutputAggregateStatus: LoadingStatus;
  searchOrderNumberOptions: string[];
  searchOrderNumberOptionsStatus: LoadingStatus;
  searchedSalesOrderMain: SalesOrderMain;
  searchedSalesOrderMainStatus: LoadingStatus;
  searchedSalesOrderExtra: SalesOrderExtra;
  searchedSalesOrderExtraStatus: LoadingStatus;
  searchedSalesOrderLineItemsPreview: SalesOrderLineItemPreview[];
  searchedSalesOrderLineItemsPreviewStatus: LoadingStatus;
  searchedSalesOrderLineItemsDetail: SalesOrderLineItemDetail[];
  searchedSalesOrderLineItemsDetailStatus: LoadingStatus;
};

export const initialState: SchedulingToolState = {
  schedulingToolProducingLocationConfigs: [],
  schedulingToolProducingLocationConfigsStatus: 'idle',
  schedulingToolProducingLocationOptions: [],
  schedulingToolProducingLocationOptionsStatus: 'idle',
  schedulingToolMCellMaps: [],
  schedulingToolMCellMapsStatus: 'idle',
  schedulingToolGuardrails: [],
  schedulingToolGuardrailsStatus: 'idle',
  schedulingToolSalesOrders: [],
  schedulingToolSalesOrdersStatus: 'idle',
  schedulingToolSalesOrderLineItems: [],
  schedulingToolSalesOrderLineItemsStatus: 'idle',
  schedulingToolUnscheduledSalesOrders: [],
  schedulingToolUnscheduledSalesOrdersStatus: 'idle',
  schedulingToolUnscheduledSalesOrderLineItems: [],
  schedulingToolUnscheduledSalesOrderLineItemsStatus: 'idle',
  schedulingToolHardHolds: [],
  schedulingToolHardHoldsStatus: 'idle',
  schedulingToolSettings: [],
  schedulingToolSettingsStatus: 'idle',
  schedulingToolChangeLogs: [],
  schedulingTooChangeLogsStatus: 'idle',
  capacityUtilizationEmployeeCounts: [],
  capacityUtilizationEmployeeCountsStatus: 'idle',
  capacityUtilizationPtoHoursTotals: [],
  capacityUtilizationPtoHoursTotalsStatus: 'idle',
  capacityUtilizationShiftHoursTotals: [],
  capacityUtilizationShiftHoursTotalsStatus: 'idle',
  schedulingToolRefreshRunning: false,
  schedulingToolRefreshRunningStatus: 'idle',
  capacityUtilizationsAggregate: {
    capacityUtilizations: [],
    capacityAvailables: [],
    capacityScheduleds: [],
    capacityUtilizationGroups: [],
    capacityUtilizationProducingLocations: [],
    capacityUtilizationUnitsCounts: [],
    producingLocationExtraMetrics: [],
    capacityUtilizationFullTimeEquivalents: [],
    capacityUtilizationWhatIfs: [],
  },
  capacityUtilizationsAggregateStatus: 'idle',
  downloadMCellMapDataStatus: 'idle',
  downloadMCellGuardrailDataStatus: 'idle',
  laborWhatIfInputResetStatus: 'idle',
  laborWhatIfOutputCalculateStatus: 'idle',
  laborWhatIfInputStatus: 'idle',
  laborWhatIfInputAggregate: {
    shiftHoursPerEmployees: [],
    employeeCounts: [],
    totalPtoHoursRecords: [],
  },
  laborWhatIfInputAggregateStatus: 'idle',
  laborWhatIfOutputAggregate: {
    capacityUtilizationLaborHoursRecords: [],
    capacityAvailableLaborHoursRecords: [],
    extraMetrics: [],
  },
  laborWhatIfOutputAggregateStatus: 'idle',
  searchOrderNumberOptions: [],
  searchOrderNumberOptionsStatus: 'idle',
  searchedSalesOrderMain: {
    orderNumber: '',
    originalOrderDate: '',
    orderAge: undefined,
    orderType: '',
    directDrop: false,
    architecturalProfileNumber: '',
    fso: undefined,
    shipComplete: false,
    orderStatus: '',
    customerName: '',
    customerState: '',
    customerPhone: '',
    customerAccountNumber: '',
    customerShipToLocationId: '',
    customerPriority: '',
    aji: '',
    alh: '',
    alv: '',
    am: '',
    dvac: '',
    planningCodesCount: undefined,
    planningCodes: '',
    inventoryCentersCount: undefined,
    inventoryCenters: '',
    productionWeek: '',
    deliveryWeeks: [],
  },
  searchedSalesOrderMainStatus: 'idle',
  searchedSalesOrderExtra: {
    originalWeekOfDeliveryDate: undefined,
    movesCount: undefined,
    uniqueTruckCount: undefined,
    lastChangedUsername: '',
    holdStatus: '',
  },
  searchedSalesOrderExtraStatus: 'idle',
  searchedSalesOrderLineItemsPreview: [],
  searchedSalesOrderLineItemsPreviewStatus: 'idle',
  searchedSalesOrderLineItemsDetail: [],
  searchedSalesOrderLineItemsDetailStatus: 'idle',
};

/**
 * Action to delete Producing Location Config in the store
 */
export const deleteProducingLocationConfigAction = createAction<string>(
  'schedulingTool/deleteProducingLocationConfigAction',
);

/**
 * Action to update Producing Location Config in the store
 */
export const updateProducingLocationConfigAction =
  createAction<STProducingLocationConfig>(
    'schedulingTool/updateProducingLocationConfigAction',
  );

/**
 * Action to delete MCell Map in the store
 */
export const deleteMCellMapAction = createAction<string>(
  'schedulingTool/deleteMCellMapAction',
);

/**
 * Action to update MCell Map in the store
 */
export const updateMCellMapAction = createAction<STMCellMapInfo>(
  'schedulingTool/updateMCellMapAction',
);

/**
 * Action to delete MCell Guardrail from the store
 */
export const deleteMCellGuardrailAction = createAction<string>(
  'schedulingTool/deleteMCellGuardrailAction',
);

/**
 * Action to update MCell Guardrail in the store
 */
export const updateMCellGuardrailAction = createAction<STMCellGuardrailInfo>(
  'schedulingTool/updateMCellGuardrailAction',
);

/**
 * Action to update the Refresh Running status
 */
export const updateRefreshRunningAction = createAction<Boolean>(
  'schedulingTool/updateRefreshRunningAction',
);

/**
 * Action to set sorted sales orders in the store
 */
export const setSortedSalesOrdersAction = createAction<STSalesOrderDto[]>(
  'schedulingTool/setSortedSalesOrdersAction',
);

/**
 * Action to set sorted sales order line items in the store
 */
export const setSortedSalesOrderLineItemsAction = createAction<
  STSalesOrderLineItemDto[]
>('schedulingTool/setSortedSalesOrderLineItemsAction');

/**
 * Action to add Labor What If Shift Hours Per Employee Input in the store
 */
export const addLaborWhatIfShiftHoursPerEmployeeInputAction =
  createAction<StLaborWhatIfInputInfo>(
    'schedulingTool/addLaborWhatIfShiftHoursPerEmployeeInputAction',
  );

/**
 * Action to add Labor What If Employee Count Input in the store
 */
export const addLaborWhatIfEmployeeCountInputAction =
  createAction<StLaborWhatIfInputInfo>(
    'schedulingTool/addLaborWhatIfEmployeeCountInputAction',
  );

/**
 * Action to add Labor What If PTO Hours Input in the store
 */
export const addLaborWhatIfPtoHoursInputAction =
  createAction<StLaborWhatIfInputInfo>(
    'schedulingTool/addLaborWhatIfPtoHoursInputAction',
  );

/**
 * Thunk for fetching Producing Location Configs from the backend and setting them in the store
 */
export const getProducingLocationConfigsThunk = createAsyncThunkWithError(
  'schedulingTool/getProducingLocationConfigsThunk',
  getProducingLocationConfigs,
  'errorFetchingProducingLocationConfigs',
);

/**
 * Thunk for fetching Producing Location Options from the backend and setting in store
 */
export const getProducingLocationOptionsThunk = createAsyncThunkWithError(
  'schedulingTool/getProducingLocationOptionsThunk',
  getProducingLocationOptions,
  'errorFetchingProducingLocationOptions',
);

/**
 * Thunk for creating a Producing Location Config and setting it in the store
 */
export const createProducingLocationConfigThunk = createAsyncThunkWithError(
  'schedulingTool/createProducingLocationConfigThunk',
  createProducingLocationConfig,
  'errorCreatingProducingLocationConfig',
);

/**
 * Thunk for fetching a Producing Location Config by id from the backend and setting the state in the store
 */
export const getProducingLocationConfigByIdThunk = createAsyncThunkWithError(
  'schedulingTool/getProducingLocationConfigByIdThunk',
  getProducingLocationConfigById,
  'errorFetchingProducingLocationConfig',
);

/**
 * Thunk for updating a Producing Location Config in the backend while optimistically updating the store
 */
export const updateProducingLocationConfigThunk = createAsyncThunkWithError(
  'schedulingTool/updateProducingLocationConfigThunk',
  async (
    info: STProducingLocationConfigInfo & { id: string },
    { dispatch },
  ) => {
    dispatch(updateProducingLocationConfigAction(info));
    return await updateProducingLocationConfig(info);
  },
  'errorUpdatingProducingLocationConfig',
);

/**
 * Thunk for adding Labor What If Shift Hours Per Employee Input
 */
export const addLaborWhatIfShiftHoursPerEmployeeInputThunk =
  createAsyncThunkWithError(
    'schedulingTool/addLaborWhatIfShiftHoursPerEmployeeInputThunk',
    async (info: StLaborWhatIfInputInfo) => {
      return await addLaborWhatIfShiftHoursPerEmployeeInput(info);
    },
    'errorAddingLaborWhatIfShiftHoursPerEmployeeInput',
  );

/**
 * Thunk for adding Labor What If Employee Count Input
 */
export const addLaborWhatIfEmployeeCountInputThunk = createAsyncThunkWithError(
  'schedulingTool/addLaborWhatIfEmployeeCountInputThunk',
  async (info: StLaborWhatIfInputInfo) => {
    return await addLaborWhatIfEmployeeCountInput(info);
  },
  'errorAddingLaborWhatIfEmployeeCountInput',
);

/**
 * Thunk for adding Labor What If PTO Hours Input
 */
export const addLaborWhatIfPtoHoursInputThunk = createAsyncThunkWithError(
  'schedulingTool/addLaborWhatIfPtoHoursInputThunk',
  async (info: StLaborWhatIfInputInfo) => {
    return await addLaborWhatIfPtoHoursInput(info);
  },
  'errorAddingLaborWhatIfPtoHoursInput',
);

/**
 * Thunk for deleting (clearing) Labor What If Input in the backend
 */
export const resetLaborWhatIfInputThunk = createAsyncThunkWithError(
  'schedulingTool/resetLaborWhatIfInputThunk',
  resetLaborWhatIfs,
  'errorResettingLaborWhatIfInput',
);

/**
 * Thunk for calculating Labor What Ifs
 */
export const calculateLaborWhatIfsThunk = createAsyncThunkWithError(
  'schedulingTool/calculateLaborWhatIfsThunk',
  calculateLaborWhatIfs,
  'errorCalculatingLaborWhatIfs',
);

/**
 * Thunk for fetching Labor What If Input Aggregate data
 */
export const getLaborWhatIfInputAggregateThunk = createAsyncThunkWithError(
  'schedulingTool/getLaborWhatIfInputAggregateThunk',
  getLaborWhatIfInputAggregate,
  'errorFetchingLaborWhatIfInputAggregate',
);

/**
 * Thunk for fetching Labor What If Output Aggregate data
 */
export const getLaborWhatIfOutputAggregateThunk = createAsyncThunkWithError(
  'schedulingTool/getLaborWhatIfOutputAggregateThunk',
  getLaborWhatIfOutputAggregate,
  'errorFetchingLaborWhatIfOutputAggregate',
);

/**
 * Thunk for deleting Producing Location Config in the backend while optimistically updating the store
 */
export const deleteProducingLocationConfigThunk = createAsyncThunkWithError(
  'schedulingTool/deleteProducingLocationConfigThunk',
  async (id: string, { dispatch }) => {
    dispatch(deleteProducingLocationConfigAction(id));
    return deleteProducingLocationConfig(id);
  },
  'errorDeletingProducingLocationConfig',
);

/**
 * Thunk for fetching MCell Maps from the backend and setting them in the store
 */
export const getMCellMapsThunk = createAsyncThunkWithError(
  'schedulingTool/getMCellMapsThunk',
  getMCellMaps,
  'errorFetchingWorkCellMaps',
);

/**
 * Thunk for creating a MCell Map and setting it in the store
 */
export const createMCellMapThunk = createAsyncThunkWithError(
  'schedulingTool/createMCellMapThunk',
  createMCellMap,
  'errorCreatingWorkCellMap',
);

/**
 * Thunk for fetching an MCell Map by id and setting it in the store
 */
export const getMCellMapByIdThunk = createAsyncThunkWithError(
  'schedulingTool/getMCellMapByIdThunk',
  getMCellMapById,
  'errorFetchingWorkCellMapById',
);

/**
 * Thunk for updating MCell Map in the backend while optimistically updating the store
 */
export const updateMCellMapThunk = createAsyncThunkWithError(
  'schedulingTool/updateMCellMapThunk',
  async (info: STMCellMapInfo & { id: string }, { dispatch }) => {
    dispatch(updateMCellMapAction(info));
    return await updateMCellMap(info);
  },
  'errorUpdatingWorkCellMap',
);

/**
 * Thunk for deleting MCell Map in the backend while optimistically updating the store
 */
export const deleteMCellMapThunk = createAsyncThunkWithError(
  'schedulingTool/deleteMCellMapThunk',
  async (id: string, { dispatch }) => {
    dispatch(deleteMCellMapAction(id));
    return deleteMCellMap(id);
  },
  'errorDeletingWorkCellMap',
);

/**
 * Thunk for fetching MCell Guardrails from the backend and setting them in the store
 */
export const getMCellGuardrailsThunk = createAsyncThunkWithError(
  'schedulingTool/getMCellGuardrails',
  getMCellGuardrails,
  'errorFetchingWorkCellGuardrails',
);

/**
 * Thunk for creating an MCell Guardrail and setting it in the store
 */
export const createMCellGuardrailThunk = createAsyncThunkWithError(
  'schedulingTool/createMCellGuardrailThunk',
  createMCellGuardrail,
  'errorCreatingWorkCellGuardrail',
);

/**
 * Thunk for fetching an MCell Guardrail by id and setting it in the store
 */
export const getMCellGuardrailByIdThunk = createAsyncThunkWithError(
  'schedulingTool/getMCellGuardrailByIdThunk',
  getMCellGuardrailById,
  'errorFetchingWorkCellGuardrail',
);

/**
 * Thunk for updating MCell Guardrail in the backend while optimistically updating the store
 */
export const updateMCellGuardrailThunk = createAsyncThunkWithError(
  'schedulingTool/updateMCellGuardrailThunk',
  async (info: STMCellGuardrailInfo & { id: string }, { dispatch }) => {
    dispatch(updateMCellGuardrailAction(info));
    return updateMCellGuardrail(info);
  },
  'errorUpdatingWorkCellGuardrail',
);

/**
 * Thunk for deleting MCell Guardrail in the backend while optimistically updating the store
 */
export const deleteMCellGuardrailThunk = createAsyncThunkWithError(
  'schedulingTool/deleteMCellGuardrailThunk',
  async (id: string, { dispatch }) => {
    dispatch(deleteMCellGuardrailAction(id));
    return deleteMCellGuardrail(id);
  },
  'errorDeletingWorkCellGuardrail',
);

/**
 * Thunk for fetching Sales Orders
 */
export const getSalesOrdersThunk = createAsyncThunkWithError(
  'schedulingTool/getSalesOrdersThunk',
  getSalesOrders,
  'errorFetchingSalesOrders',
);

/**
 * Thunk for fetching Sales Order Line Items
 */
export const getSalesOrderLineItemsThunk = createAsyncThunkWithError(
  'schedulingTool/getSalesOrderLineItemsThunk',
  getSalesOrderLineItems,
  'errorFetchingSalesOrderLineItems',
);

/**
 * Thunk for fetching Unscheduled Sales Order Line Items
 */
export const getUnscheduledSalesOrdersThunk = createAsyncThunkWithError(
  'schedulingTool/getUnscheduledSalesOrdersThunk',
  getUnscheduledSalesOrders,
  'errorFetchingUnscheduledSalesOrders',
);

/**
 * Thunk for fetching Unscheduled Sales Order Line Items
 */
export const getUnscheduledSalesOrderLineItemsThunk = createAsyncThunkWithError(
  'schedulingTool/getUnscheduledSalesOrderLineItemsThunk',
  getUnscheduledSalesOrderLineItems,
  'errorFetchingUnscheduledSalesOrderLineItems',
);

/**
 * Thunk for fetching Hard Holds
 */
export const getHardHoldsThunk = createAsyncThunkWithError(
  'schedulingTool/getHardHoldsThunk',
  getHardHolds,
  'errorFetchingHardHolds',
);

/**
 * Thunk for fetching a Setting for an inventory center with specified key
 */
export const getSettingThunk = createAsyncThunkWithError(
  'schedulingTool/getSettingThunk',
  getSetting,
  'errorFetchingSetting',
);

/**
 * Thunk for fetching Settings in an inventory center
 */
export const getSettingsThunk = createAsyncThunkWithError(
  'schedulingTool/getSettingsThunk',
  getSettings,
  'errorFetchingSettings',
);

/**
 * Thunk for setting a Scheduling Tool Setting
 */
export const setSettingThunk = createAsyncThunkWithError(
  'schedulingTool/setSettingThunk',
  setSetting,
  'errorUpdatingSetting',
);

/**
 * Thunk for resetting a Setting to its default value
 */
export const resetSettingToDefaultThunk = createAsyncThunkWithError(
  'schedulingTool/resetSettingToDefault',
  resetSettingToDefault,
  'errorResettingSettingToDefault',
);

/**
 * Thunk for fetching Change Logs for user
 */
export const getChangeLogsThunk = createAsyncThunkWithError(
  'schedulingTool/getChangeLogsThunk',
  getChangeLogs,
  'errorFetchingChangeLogs',
);

/**
 * Thunk for fetching Change Log
 */
export const getChangeLogThunk = createAsyncThunkWithError(
  'schedulingTool/getChangeLogThunk',
  getChangeLog,
  'errorFetchingChangeLog',
);

/**
 * Thunk for fetching all Change Logs for all users
 */
export const getAllChangeLogsThunk = createAsyncThunkWithError(
  'schedulingTool/getAllChangeLogsThunk',
  getAllChangeLogs,
  'errorFetchingAllChangeLogs',
);

/**
 * Thunk for adding or updating change logs
 */
export const addOrUpdateChangeLogsThunk = createAsyncThunkWithError(
  'schedulingTool/addOrUpdateChangeLogsThunk',
  addOrUpdateChangeLogs,
  'errorAddingChangeLogs',
);

/**
 * Thunk for committing change logs to ICIM
 */
export const commitChangeLogsToICIMThunk = createAsyncThunkWithError(
  'schedulingTool/commitChangeLogsToICIMThunk',
  commitChangeLogsToICIM,
  'errorCommittingChangeLogs',
);

/**
 * Thunk for bulk delete of change logs
 */
export const deleteChangeLogsBulkThunk = createAsyncThunkWithError(
  'schedulingTool/deleteChangeLogsBulkThunk',
  async (ids: string[]) => {
    return deleteChangeLogBulk(ids);
  },
  'errorBulkDeletingChangeLogs',
);

/**
 * Thunk for deleting (clearing) all change logs for a user
 */
export const deleteChangeLogsThunk = createAsyncThunkWithError(
  'schedulingTool/deleteChangeLogsThunk',
  deleteChangeLogs,
  'errorDeletingChangeLogs',
);

/**
 * Thunk for deleting a change log
 */
export const deleteChangeLogThunk = createAsyncThunkWithError(
  'schedulingTool/deleteChangeLogThunk',
  deleteChangeLog,
  'errorDeletingChangeLog',
);

/**
 * Thunk for manually refreshing Scheduling Tool data
 */
export const triggerManualRefreshThunk = createAsyncThunkWithError(
  'schedulingTool/triggerManualRefreshThunk',
  triggerManualRefresh,
  'errorManuallyRefreshingSchedulingToolData',
);

/**
 * Thunk for determining whether a refresh is in flight
 */
export const isRefreshRunningThunk = createAsyncThunkWithError(
  'schedulingTool/isRefreshRunningThunk',
  isRefreshRunning,
  'errorCheckingRefreshStatus',
);

/**
 * Thunk for fetching Capacity Utilization Employee Counts
 */
export const getCapacityUtilizationEmployeeCountsThunk =
  createAsyncThunkWithError(
    'schedulingTool/getCapacityUtilizationEmployeeCountsThunk',
    getCapacityUtilizationEmployeeCounts,
    'errorFetchingCapacityUtilizationEmployeeCounts',
  );

/**
 * Thunk for fetching Capacity Utilization PTO Hour Totals
 */
export const getCapacityUtilizationPtoHoursTotalsThunk =
  createAsyncThunkWithError(
    'schedulingTool/getCapacityUtilizationPtoHoursTotalsThunk',
    getCapacityUtilizationPtoHoursTotals,
    'errorFetchingCapacityUtilizationPtoHoursTotals',
  );

/**
 * Thunk for fetching Capacity Utilization Shift Hour Totals
 */
export const getCapacityUtilizationShiftHoursTotalsThunk =
  createAsyncThunkWithError(
    'schedulingTool/getCapacityUtilizationShiftHoursTotalsThunk',
    getCapacityUtilizationShiftHoursTotals,
    'errorFetchingCapacityUtilizationShiftHoursTotals',
  );

/**
 * Thunk for fetching Capacity Utilizations Aggregate data
 */
export const getCapacityUtilizationsAggregateThunk = createAsyncThunkWithError(
  'schedulingTool/getCapacityUtilizationsAggregateThunk',
  getCapacityUtilizationsAggregate,
  'errorFetchingCapacityUtilizationsAggregate',
);

/**
 * Thunk for fetching MCell Map data as csv file
 */
export const downloadMCellMapDataThunk = createAsyncThunkWithError(
  'schedulingTool/downloadMCellMapDataThunk',
  async (params: { inventoryCenterId: string; filename: string }) => {
    const data = await downloadMCellMapData(params.inventoryCenterId);
    saveBlobToFile(data, params.filename);
  },
  'errorDownloadingWorkCellMapData',
);

/**
 * Thunk for fetching MCell Guardrail data as csv file
 */
export const downloadMCellGuardrailDataThunk = createAsyncThunkWithError(
  'schedulingTool/downloadMCellGuardrailDataThunk',
  async (params: { inventoryCenterId: string; filename: string }) => {
    const data = await downloadMCellGuardrailData(params.inventoryCenterId);
    saveBlobToFile(data, params.filename);
  },
  'errorDownloadingWorkCellGuardrailData',
);

/**
 * Thunk for fetching search order number options
 */
export const getSearchOrderNumberOptionsThunk = createAsyncThunkWithError(
  'schedulingTool/getSearchOrderNumberOptionsThunk',
  searchOrderNumber,
  'errorFetchingSearchOrderNumberOptions',
);

/**
 * Thunk for fetching main sales order info on a searched order
 */
export const getSearchedSalesOrderMainThunk = createAsyncThunkWithError(
  'schedulingTool/getSearchedSalesOrderMainThunk',
  getSalesOrderMain,
  'errorFetchingSearchedSalesOrderMain',
);

/**
 * Thunk for fetching extra sales order info on a searched order
 */
export const getSearchedSalesOrderExtraThunk = createAsyncThunkWithError(
  'schedulingTool/getSearchedSalesOrderExtraThunk',
  getSalesOrderExtra,
  'errorFetchingSearchedSalesOrderExtra',
);

/**
 * Thunk for fetching sales order line items preview on a searched order
 */
export const getSearchedSalesOrderLineItemsPreviewThunk =
  createAsyncThunkWithError(
    'schedulingTool/getSearchedSalesOrderLineItemsPreviewThunk',
    getSalesOrderLineItemsPreview,
    'errorFetchingSearchSalesOrderLineItemsPreview',
  );

/**
 * Thunk for fetching sales order line items detail on a searched order
 */
export const getSearchedSalesOrderLineItemsDetailThunk =
  createAsyncThunkWithError(
    'schedulingTool/getSearchedSalesOrderLineItemsDetailThunk',
    getSalesOrderLineItemsDetail,
    'errorFetchingSearchedSalesOrderLineItemsDetail',
  );

export const schedulingToolSlice = createSlice({
  name: 'schedulingTool',
  initialState,
  reducers: {
    deleteProducingLocationConfigAction: (state, action) => {
      const index = state.schedulingToolProducingLocationConfigs.findIndex(
        (x) => x.id === action.payload,
      );
      if (index > -1) {
        state.schedulingToolProducingLocationConfigs.splice(index, 1);
      }
    },
    updateProducingLocationConfigAction: (state, action) => {
      const updateIndex =
        state.schedulingToolProducingLocationConfigs.findIndex(
          (x) => x.id === action.payload.id,
        );
      if (updateIndex > -1) {
        state.schedulingToolProducingLocationConfigs[updateIndex] =
          action.payload;
      }
    },
    deleteMCellMapAction: (state, action) => {
      const index = state.schedulingToolMCellMaps.findIndex(
        (x) => x.id === action.payload,
      );
      if (index > -1) {
        state.schedulingToolMCellMaps.splice(index, 1);
      }
    },
    updateMCellMapAction: (state, action) => {
      const updateIndex = state.schedulingToolMCellMaps.findIndex(
        (x) => x.id === action.payload.id,
      );
      if (updateIndex > -1) {
        state.schedulingToolMCellMaps[updateIndex] = action.payload;
      }
    },
    deleteMCellGuardrailAction: (state, action) => {
      const index = state.schedulingToolGuardrails.findIndex(
        (x) => x.id === action.payload,
      );
      if (index > -1) {
        state.schedulingToolGuardrails.splice(index, 1);
      }
    },
    updateMCellGuardrailAction: (state, action) => {
      const updateIndex = state.schedulingToolGuardrails.findIndex(
        (x) => x.id === action.payload.id,
      );
      if (updateIndex > -1) {
        state.schedulingToolGuardrails[updateIndex] = action.payload;
      }
    },
    updateRefreshRunningAction: (state, action) => {
      state.schedulingToolRefreshRunning = action.payload;
    },
    addLaborWhatIfShiftHoursPerEmployeeInputAction: (state, action) => {
      const updateIndex =
        state.laborWhatIfInputAggregate.shiftHoursPerEmployees.findIndex(
          (x) =>
            x.mCellId === action.payload.mCellId &&
            x.week === action.payload.week &&
            x.shift === action.payload.shift,
        );
      state.laborWhatIfInputAggregate.shiftHoursPerEmployees[
        updateIndex
      ].value = action.payload.value;
      state.laborWhatIfInputAggregate.shiftHoursPerEmployees[
        updateIndex
      ].hasChanged = true;
    },
    addLaborWhatIfEmployeeCountInputAction: (state, action) => {
      const updateIndex =
        state.laborWhatIfInputAggregate.employeeCounts.findIndex(
          (x) =>
            x.mCellId === action.payload.mCellId &&
            x.week === action.payload.week &&
            x.shift === action.payload.shift,
        );
      state.laborWhatIfInputAggregate.employeeCounts[updateIndex].value =
        action.payload.value;
      state.laborWhatIfInputAggregate.employeeCounts[updateIndex].hasChanged =
        true;
    },
    addLaborWhatIfPtoHoursInputAction: (state, action) => {
      const updateIndex =
        state.laborWhatIfInputAggregate.totalPtoHoursRecords.findIndex(
          (x) =>
            x.mCellId === action.payload.mCellId &&
            x.week === action.payload.week &&
            x.shift === action.payload.shift,
        );
      state.laborWhatIfInputAggregate.totalPtoHoursRecords[updateIndex].value =
        action.payload.value;
      state.laborWhatIfInputAggregate.totalPtoHoursRecords[
        updateIndex
      ].hasChanged = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducingLocationConfigsThunk.pending, (state) => {
        state.schedulingToolProducingLocationConfigsStatus = 'loading';
      })
      .addCase(
        getProducingLocationConfigsThunk.fulfilled,
        (state, { payload }) => {
          state.schedulingToolProducingLocationConfigsStatus = 'succeeded';
          state.schedulingToolProducingLocationConfigs = payload;
        },
      )
      .addCase(getProducingLocationConfigsThunk.rejected, (state) => {
        state.schedulingToolProducingLocationConfigsStatus = 'failed';
      })
      .addCase(getProducingLocationOptionsThunk.pending, (state) => {
        state.schedulingToolProducingLocationOptionsStatus = 'loading';
      })
      .addCase(
        getProducingLocationOptionsThunk.fulfilled,
        (state, { payload }) => {
          state.schedulingToolProducingLocationOptionsStatus = 'succeeded';
          state.schedulingToolProducingLocationOptions = payload;
        },
      )
      .addCase(getProducingLocationOptionsThunk.rejected, (state) => {
        state.schedulingToolProducingLocationOptionsStatus = 'failed';
      })
      .addCase(createProducingLocationConfigThunk.pending, (state) => {
        state.schedulingToolProducingLocationConfigsStatus = 'loading';
      })
      .addCase(createProducingLocationConfigThunk.fulfilled, (state) => {
        state.schedulingToolProducingLocationConfigsStatus = 'succeeded';
      })
      .addCase(createProducingLocationConfigThunk.rejected, (state) => {
        state.schedulingToolProducingLocationConfigsStatus = 'failed';
      })
      .addCase(getProducingLocationConfigByIdThunk.pending, (state) => {
        state.schedulingToolProducingLocationConfigsStatus = 'loading';
      })
      .addCase(
        getProducingLocationConfigByIdThunk.fulfilled,
        (state, { payload }) => {
          state.schedulingToolProducingLocationConfigsStatus = 'succeeded';
          const index = state.schedulingToolProducingLocationConfigs.findIndex(
            (x) => x.id === payload.id,
          );
          if (index > -1) {
            state.schedulingToolProducingLocationConfigs[index] = payload;
          } else {
            state.schedulingToolProducingLocationConfigs.push(payload);
          }
        },
      )
      .addCase(getProducingLocationConfigByIdThunk.rejected, (state) => {
        state.schedulingToolProducingLocationConfigsStatus = 'failed';
      })
      .addCase(updateProducingLocationConfigThunk.pending, (state) => {
        state.schedulingToolProducingLocationConfigsStatus = 'loading';
      })
      .addCase(
        updateProducingLocationConfigThunk.fulfilled,
        (state, { payload }) => {
          state.schedulingToolProducingLocationConfigsStatus = 'succeeded';
          const index = state.schedulingToolProducingLocationConfigs.findIndex(
            (x) => x.id === payload.id,
          );
          if (index > -1) {
            state.schedulingToolProducingLocationConfigs[index] = payload;
          } else {
            state.schedulingToolProducingLocationConfigs.push(payload);
          }
        },
      )
      .addCase(updateProducingLocationConfigThunk.rejected, (state) => {
        state.schedulingToolProducingLocationConfigsStatus = 'failed';
      })
      .addCase(deleteProducingLocationConfigThunk.pending, (state) => {
        state.schedulingToolProducingLocationConfigsStatus = 'loading';
      })
      .addCase(deleteProducingLocationConfigThunk.fulfilled, (state) => {
        state.schedulingToolProducingLocationConfigsStatus = 'succeeded';
      })
      .addCase(deleteProducingLocationConfigThunk.rejected, (state) => {
        state.schedulingToolProducingLocationConfigsStatus = 'failed';
      })
      .addCase(getMCellMapsThunk.pending, (state) => {
        state.schedulingToolMCellMapsStatus = 'loading';
      })
      .addCase(getMCellMapsThunk.fulfilled, (state, { payload }) => {
        state.schedulingToolMCellMapsStatus = 'succeeded';
        state.schedulingToolMCellMaps = payload;
      })
      .addCase(getMCellMapsThunk.rejected, (state) => {
        state.schedulingToolMCellMapsStatus = 'failed';
      })
      .addCase(createMCellMapThunk.pending, (state) => {
        state.schedulingToolMCellMapsStatus = 'loading';
      })
      .addCase(createMCellMapThunk.fulfilled, (state) => {
        state.schedulingToolMCellMapsStatus = 'succeeded';
      })
      .addCase(createMCellMapThunk.rejected, (state) => {
        state.schedulingToolMCellMapsStatus = 'failed';
      })
      .addCase(getMCellMapByIdThunk.pending, (state) => {
        state.schedulingToolMCellMapsStatus = 'loading';
      })
      .addCase(getMCellMapByIdThunk.fulfilled, (state, { payload }) => {
        state.schedulingToolMCellMapsStatus = 'succeeded';
        const index = state.schedulingToolMCellMaps.findIndex(
          (x) => x.id === payload.id,
        );
        if (index > -1) {
          state.schedulingToolMCellMaps[index] = payload;
        } else {
          state.schedulingToolMCellMaps.push(payload);
        }
      })
      .addCase(getMCellMapByIdThunk.rejected, (state) => {
        state.schedulingToolMCellMapsStatus = 'failed';
      })
      .addCase(updateMCellMapThunk.pending, (state) => {
        state.schedulingToolMCellMapsStatus = 'loading';
      })
      .addCase(updateMCellMapThunk.fulfilled, (state, { payload }) => {
        state.schedulingToolMCellMapsStatus = 'succeeded';
        const index = state.schedulingToolMCellMaps.findIndex(
          (x) => x.id === payload.id,
        );
        if (index > -1) {
          state.schedulingToolMCellMaps[index] = payload;
        } else {
          state.schedulingToolMCellMaps.push(payload);
        }
      })
      .addCase(updateMCellMapThunk.rejected, (state) => {
        state.schedulingToolMCellMapsStatus = 'failed';
      })
      .addCase(deleteMCellMapThunk.pending, (state) => {
        state.schedulingToolMCellMapsStatus = 'loading';
      })
      .addCase(deleteMCellMapThunk.fulfilled, (state) => {
        state.schedulingToolMCellMapsStatus = 'succeeded';
      })
      .addCase(deleteMCellMapThunk.rejected, (state) => {
        state.schedulingToolMCellMapsStatus = 'failed';
      })
      .addCase(getMCellGuardrailsThunk.pending, (state) => {
        state.schedulingToolGuardrailsStatus = 'loading';
      })
      .addCase(getMCellGuardrailsThunk.fulfilled, (state, { payload }) => {
        state.schedulingToolGuardrailsStatus = 'succeeded';
        state.schedulingToolGuardrails = payload;
      })
      .addCase(getMCellGuardrailsThunk.rejected, (state) => {
        state.schedulingToolGuardrailsStatus = 'failed';
      })
      .addCase(createMCellGuardrailThunk.pending, (state) => {
        state.schedulingToolGuardrailsStatus = 'loading';
      })
      .addCase(createMCellGuardrailThunk.fulfilled, (state) => {
        state.schedulingToolGuardrailsStatus = 'succeeded';
      })
      .addCase(createMCellGuardrailThunk.rejected, (state) => {
        state.schedulingToolGuardrailsStatus = 'failed';
      })
      .addCase(getMCellGuardrailByIdThunk.pending, (state) => {
        state.schedulingToolGuardrailsStatus = 'loading';
      })
      .addCase(getMCellGuardrailByIdThunk.fulfilled, (state, { payload }) => {
        state.schedulingToolGuardrailsStatus = 'succeeded';
        const index = state.schedulingToolGuardrails.findIndex(
          (x) => x.id === payload.id,
        );
        if (index > -1) {
          state.schedulingToolGuardrails[index] = payload;
        } else {
          state.schedulingToolGuardrails.push(payload);
        }
      })
      .addCase(getMCellGuardrailByIdThunk.rejected, (state) => {
        state.schedulingToolGuardrailsStatus = 'failed';
      })
      .addCase(updateMCellGuardrailThunk.pending, (state) => {
        state.schedulingToolGuardrailsStatus = 'loading';
      })
      .addCase(updateMCellGuardrailThunk.fulfilled, (state, { payload }) => {
        state.schedulingToolGuardrailsStatus = 'succeeded';
        const index = state.schedulingToolGuardrails.findIndex(
          (x) => x.id === payload.id,
        );
        if (index > -1) {
          state.schedulingToolGuardrails[index] = payload;
        } else {
          state.schedulingToolGuardrails.push(payload);
        }
      })
      .addCase(updateMCellGuardrailThunk.rejected, (state) => {
        state.schedulingToolGuardrailsStatus = 'failed';
      })
      .addCase(deleteMCellGuardrailThunk.pending, (state) => {
        state.schedulingToolGuardrailsStatus = 'loading';
      })
      .addCase(deleteMCellGuardrailThunk.fulfilled, (state) => {
        state.schedulingToolGuardrailsStatus = 'succeeded';
      })
      .addCase(deleteMCellGuardrailThunk.rejected, (state) => {
        state.schedulingToolGuardrailsStatus = 'failed';
      })
      .addCase(getSalesOrdersThunk.pending, (state) => {
        state.schedulingToolSalesOrdersStatus = 'loading';
      })
      .addCase(getSalesOrdersThunk.fulfilled, (state, { payload }) => {
        state.schedulingToolSalesOrdersStatus = 'succeeded';
        state.schedulingToolSalesOrders = payload;
      })
      .addCase(getSalesOrdersThunk.rejected, (state) => {
        state.schedulingToolSalesOrdersStatus = 'failed';
      })
      .addCase(getSalesOrderLineItemsThunk.pending, (state) => {
        state.schedulingToolSalesOrderLineItemsStatus = 'loading';
      })
      .addCase(getSalesOrderLineItemsThunk.fulfilled, (state, { payload }) => {
        state.schedulingToolSalesOrderLineItemsStatus = 'succeeded';
        state.schedulingToolSalesOrderLineItems = payload;
      })
      .addCase(getSalesOrderLineItemsThunk.rejected, (state) => {
        state.schedulingToolSalesOrderLineItemsStatus = 'failed';
      })
      .addCase(getUnscheduledSalesOrdersThunk.pending, (state) => {
        state.schedulingToolUnscheduledSalesOrdersStatus = 'loading';
      })
      .addCase(
        getUnscheduledSalesOrdersThunk.fulfilled,
        (state, { payload }) => {
          state.schedulingToolUnscheduledSalesOrdersStatus = 'succeeded';
          state.schedulingToolUnscheduledSalesOrders = payload;
        },
      )
      .addCase(getUnscheduledSalesOrdersThunk.rejected, (state) => {
        state.schedulingToolUnscheduledSalesOrdersStatus = 'failed';
      })
      .addCase(getUnscheduledSalesOrderLineItemsThunk.pending, (state) => {
        state.schedulingToolUnscheduledSalesOrderLineItemsStatus = 'loading';
      })
      .addCase(
        getUnscheduledSalesOrderLineItemsThunk.fulfilled,
        (state, { payload }) => {
          state.schedulingToolUnscheduledSalesOrderLineItemsStatus =
            'succeeded';
          state.schedulingToolUnscheduledSalesOrderLineItems = payload;
        },
      )
      .addCase(getUnscheduledSalesOrderLineItemsThunk.rejected, (state) => {
        state.schedulingToolUnscheduledSalesOrderLineItemsStatus = 'failed';
      })
      .addCase(getHardHoldsThunk.pending, (state) => {
        state.schedulingToolHardHoldsStatus = 'loading';
      })
      .addCase(getHardHoldsThunk.fulfilled, (state, { payload }) => {
        state.schedulingToolHardHoldsStatus = 'succeeded';
        state.schedulingToolHardHolds = payload;
      })
      .addCase(getHardHoldsThunk.rejected, (state) => {
        state.schedulingToolHardHoldsStatus = 'failed';
      })
      .addCase(getSettingThunk.pending, (state) => {
        state.schedulingToolSettingsStatus = 'loading';
      })
      .addCase(getSettingThunk.fulfilled, (state, { payload }) => {
        state.schedulingToolSettingsStatus = 'succeeded';
        const index = state.schedulingToolSettings.findIndex(
          (x) =>
            x.key === payload.key &&
            x.inventoryCenterId === payload.inventoryCenterId,
        );
        if (index > -1) {
          state.schedulingToolSettings[index] = payload;
        } else {
          state.schedulingToolSettings.push(payload);
        }
      })
      .addCase(getSettingThunk.rejected, (state) => {
        state.schedulingToolSettingsStatus = 'failed';
      })
      .addCase(getSettingsThunk.pending, (state) => {
        state.schedulingToolSettingsStatus = 'loading';
      })
      .addCase(getSettingsThunk.fulfilled, (state, { payload }) => {
        state.schedulingToolSettingsStatus = 'succeeded';
        state.schedulingToolSettings = payload;
      })
      .addCase(getSettingsThunk.rejected, (state) => {
        state.schedulingToolSettingsStatus = 'failed';
      })
      .addCase(setSettingThunk.pending, (state) => {
        state.schedulingToolSettingsStatus = 'loading';
      })
      .addCase(setSettingThunk.fulfilled, (state, { payload }) => {
        state.schedulingToolSettingsStatus = 'succeeded';
        const index = state.schedulingToolSettings.findIndex(
          (x) =>
            x.key === payload.key &&
            x.inventoryCenterId === payload.inventoryCenterId,
        );
        if (index > -1) {
          state.schedulingToolSettings[index] = payload;
        } else {
          state.schedulingToolSettings.push(payload);
        }
      })
      .addCase(setSettingThunk.rejected, (state) => {
        state.schedulingToolSettingsStatus = 'failed';
      })
      .addCase(resetSettingToDefaultThunk.pending, (state) => {
        state.schedulingToolSettingsStatus = 'loading';
      })
      .addCase(resetSettingToDefaultThunk.fulfilled, (state, { payload }) => {
        state.schedulingToolSettingsStatus = 'succeeded';
        const index = state.schedulingToolSettings.findIndex(
          (x) =>
            x.key === payload.key &&
            x.inventoryCenterId === payload.inventoryCenterId,
        );
        if (index > -1) {
          state.schedulingToolSettings[index] = payload;
        } else {
          state.schedulingToolSettings.push(payload);
        }
      })
      .addCase(resetSettingToDefaultThunk.rejected, (state) => {
        state.schedulingToolSettingsStatus = 'loading';
      })
      .addCase(getChangeLogsThunk.pending, (state) => {
        state.schedulingTooChangeLogsStatus = 'loading';
      })
      .addCase(getChangeLogsThunk.fulfilled, (state, { payload }) => {
        state.schedulingTooChangeLogsStatus = 'succeeded';
        state.schedulingToolChangeLogs = payload;
      })
      .addCase(getChangeLogsThunk.rejected, (state) => {
        state.schedulingTooChangeLogsStatus = 'failed';
      })
      .addCase(getChangeLogThunk.pending, (state) => {
        state.schedulingTooChangeLogsStatus = 'loading';
      })
      .addCase(getChangeLogThunk.fulfilled, (state) => {
        state.schedulingTooChangeLogsStatus = 'succeeded';
      })
      .addCase(getChangeLogThunk.rejected, (state) => {
        state.schedulingTooChangeLogsStatus = 'failed';
      })
      .addCase(getAllChangeLogsThunk.pending, (state) => {
        state.schedulingTooChangeLogsStatus = 'loading';
      })
      .addCase(getAllChangeLogsThunk.fulfilled, (state, { payload }) => {
        state.schedulingTooChangeLogsStatus = 'succeeded';
        state.schedulingToolChangeLogs = payload;
      })
      .addCase(getAllChangeLogsThunk.rejected, (state) => {
        state.schedulingTooChangeLogsStatus = 'failed';
      })
      .addCase(addOrUpdateChangeLogsThunk.pending, (state) => {
        state.schedulingTooChangeLogsStatus = 'loading';
      })
      .addCase(addOrUpdateChangeLogsThunk.fulfilled, (state) => {
        state.schedulingTooChangeLogsStatus = 'succeeded';
      })
      .addCase(addOrUpdateChangeLogsThunk.rejected, (state) => {
        state.schedulingTooChangeLogsStatus = 'failed';
      })
      .addCase(commitChangeLogsToICIMThunk.pending, (state) => {
        state.schedulingTooChangeLogsStatus = 'loading';
      })
      .addCase(commitChangeLogsToICIMThunk.fulfilled, (state) => {
        state.schedulingTooChangeLogsStatus = 'succeeded';
      })
      .addCase(commitChangeLogsToICIMThunk.rejected, (state) => {
        state.schedulingTooChangeLogsStatus = 'failed';
      })
      .addCase(deleteChangeLogsBulkThunk.pending, (state) => {
        state.schedulingTooChangeLogsStatus = 'loading';
      })
      .addCase(deleteChangeLogsBulkThunk.fulfilled, (state) => {
        state.schedulingTooChangeLogsStatus = 'succeeded';
      })
      .addCase(deleteChangeLogsBulkThunk.rejected, (state) => {
        state.schedulingTooChangeLogsStatus = 'failed';
      })
      .addCase(deleteChangeLogThunk.pending, (state) => {
        state.schedulingTooChangeLogsStatus = 'loading';
      })
      .addCase(deleteChangeLogThunk.fulfilled, (state) => {
        state.schedulingTooChangeLogsStatus = 'succeeded';
      })
      .addCase(deleteChangeLogThunk.rejected, (state) => {
        state.schedulingTooChangeLogsStatus = 'failed';
      })
      .addCase(deleteChangeLogsThunk.pending, (state) => {
        state.schedulingTooChangeLogsStatus = 'loading';
      })
      .addCase(deleteChangeLogsThunk.fulfilled, (state) => {
        state.schedulingTooChangeLogsStatus = 'succeeded';
      })
      .addCase(deleteChangeLogsThunk.rejected, (state) => {
        state.schedulingTooChangeLogsStatus = 'failed';
      })
      .addCase(isRefreshRunningThunk.pending, (state) => {
        state.schedulingToolRefreshRunningStatus = 'loading';
      })
      .addCase(isRefreshRunningThunk.fulfilled, (state, { payload }) => {
        state.schedulingToolRefreshRunningStatus = 'succeeded';
        state.schedulingToolRefreshRunning = payload;
      })
      .addCase(isRefreshRunningThunk.rejected, (state) => {
        state.schedulingToolRefreshRunningStatus = 'failed';
      })
      .addCase(getCapacityUtilizationEmployeeCountsThunk.pending, (state) => {
        state.capacityUtilizationEmployeeCountsStatus = 'loading';
      })
      .addCase(
        getCapacityUtilizationEmployeeCountsThunk.fulfilled,
        (state, { payload }) => {
          state.capacityUtilizationEmployeeCountsStatus = 'succeeded';
          state.capacityUtilizationEmployeeCounts = payload;
        },
      )
      .addCase(getCapacityUtilizationEmployeeCountsThunk.rejected, (state) => {
        state.capacityUtilizationEmployeeCountsStatus = 'failed';
      })
      .addCase(getCapacityUtilizationPtoHoursTotalsThunk.pending, (state) => {
        state.capacityUtilizationPtoHoursTotalsStatus = 'loading';
      })
      .addCase(
        getCapacityUtilizationPtoHoursTotalsThunk.fulfilled,
        (state, { payload }) => {
          state.capacityUtilizationPtoHoursTotalsStatus = 'succeeded';
          state.capacityUtilizationPtoHoursTotals = payload;
        },
      )
      .addCase(getCapacityUtilizationPtoHoursTotalsThunk.rejected, (state) => {
        state.capacityUtilizationPtoHoursTotalsStatus = 'failed';
      })
      .addCase(getCapacityUtilizationShiftHoursTotalsThunk.pending, (state) => {
        state.capacityUtilizationShiftHoursTotalsStatus = 'loading';
      })
      .addCase(
        getCapacityUtilizationShiftHoursTotalsThunk.fulfilled,
        (state, { payload }) => {
          state.capacityUtilizationShiftHoursTotalsStatus = 'succeeded';
          state.capacityUtilizationShiftHoursTotals = payload;
        },
      )
      .addCase(
        getCapacityUtilizationShiftHoursTotalsThunk.rejected,
        (state) => {
          state.capacityUtilizationShiftHoursTotalsStatus = 'failed';
        },
      )
      .addCase(getCapacityUtilizationsAggregateThunk.pending, (state) => {
        state.capacityUtilizationsAggregateStatus = 'loading';
      })
      .addCase(
        getCapacityUtilizationsAggregateThunk.fulfilled,
        (state, { payload }) => {
          state.capacityUtilizationsAggregateStatus = 'succeeded';
          state.capacityUtilizationsAggregate = payload;
        },
      )
      .addCase(getCapacityUtilizationsAggregateThunk.rejected, (state) => {
        state.capacityUtilizationsAggregateStatus = 'failed';
      })
      .addCase(downloadMCellMapDataThunk.pending, (state) => {
        state.downloadMCellMapDataStatus = 'loading';
      })
      .addCase(downloadMCellMapDataThunk.fulfilled, (state) => {
        state.downloadMCellMapDataStatus = 'succeeded';
      })
      .addCase(downloadMCellMapDataThunk.rejected, (state) => {
        state.downloadMCellMapDataStatus = 'failed';
      })
      .addCase(downloadMCellGuardrailDataThunk.pending, (state) => {
        state.downloadMCellGuardrailDataStatus = 'loading';
      })
      .addCase(downloadMCellGuardrailDataThunk.fulfilled, (state) => {
        state.downloadMCellGuardrailDataStatus = 'succeeded';
      })
      .addCase(downloadMCellGuardrailDataThunk.rejected, (state) => {
        state.downloadMCellGuardrailDataStatus = 'failed';
      })
      .addCase(
        addLaborWhatIfShiftHoursPerEmployeeInputThunk.pending,
        (state) => {
          state.laborWhatIfInputAggregateStatus = 'loading';
        },
      )
      .addCase(
        addLaborWhatIfShiftHoursPerEmployeeInputThunk.fulfilled,
        (state, { payload }) => {
          state.laborWhatIfInputAggregateStatus = 'succeeded';
          const existingIndex =
            state.laborWhatIfInputAggregate.shiftHoursPerEmployees.findIndex(
              (item) =>
                item.week === payload.week &&
                item.shift === payload.shift &&
                item.mCellId === payload.mCellId,
            );
          if (existingIndex >= 0) {
            const existingItem =
              state.laborWhatIfInputAggregate.shiftHoursPerEmployees[
                existingIndex
              ];
            if (
              existingItem.value !== payload.value ||
              existingItem.hasChanged !== payload.hasChanged
            ) {
              state.laborWhatIfInputAggregate.shiftHoursPerEmployees[
                existingIndex
              ] = payload;
            }
          }
        },
      )
      .addCase(
        addLaborWhatIfShiftHoursPerEmployeeInputThunk.rejected,
        (state) => {
          state.laborWhatIfInputAggregateStatus = 'failed';
        },
      )
      .addCase(addLaborWhatIfEmployeeCountInputThunk.pending, (state) => {
        state.laborWhatIfInputAggregateStatus = 'loading';
      })
      .addCase(
        addLaborWhatIfEmployeeCountInputThunk.fulfilled,
        (state, { payload }) => {
          state.laborWhatIfInputAggregateStatus = 'succeeded';
          const existingIndex =
            state.laborWhatIfInputAggregate.employeeCounts.findIndex(
              (item) =>
                item.week === payload.week &&
                item.shift === payload.shift &&
                item.mCellId === payload.mCellId,
            );
          if (existingIndex >= 0) {
            const existingItem =
              state.laborWhatIfInputAggregate.employeeCounts[existingIndex];
            if (
              existingItem.value !== payload.value ||
              existingItem.hasChanged !== payload.hasChanged
            ) {
              state.laborWhatIfInputAggregate.employeeCounts[existingIndex] =
                payload;
            }
          }
        },
      )
      .addCase(addLaborWhatIfEmployeeCountInputThunk.rejected, (state) => {
        state.laborWhatIfInputAggregateStatus = 'failed';
      })
      .addCase(addLaborWhatIfPtoHoursInputThunk.pending, (state) => {
        state.laborWhatIfInputAggregateStatus = 'loading';
      })
      .addCase(
        addLaborWhatIfPtoHoursInputThunk.fulfilled,
        (state, { payload }) => {
          state.laborWhatIfInputAggregateStatus = 'succeeded';
          const existingIndex =
            state.laborWhatIfInputAggregate.totalPtoHoursRecords.findIndex(
              (item) =>
                item.week === payload.week &&
                item.shift === payload.shift &&
                item.mCellId === payload.mCellId,
            );
          if (existingIndex >= 0) {
            const existingItem =
              state.laborWhatIfInputAggregate.totalPtoHoursRecords[
                existingIndex
              ];
            if (
              existingItem.value !== payload.value ||
              existingItem.hasChanged !== payload.hasChanged
            ) {
              state.laborWhatIfInputAggregate.totalPtoHoursRecords[
                existingIndex
              ] = payload;
            }
          }
        },
      )
      .addCase(addLaborWhatIfPtoHoursInputThunk.rejected, (state) => {
        state.laborWhatIfInputAggregateStatus = 'failed';
      })
      .addCase(getLaborWhatIfInputAggregateThunk.pending, (state) => {
        state.laborWhatIfInputAggregateStatus = 'loading';
      })
      .addCase(
        getLaborWhatIfInputAggregateThunk.fulfilled,
        (state, { payload }) => {
          state.laborWhatIfInputAggregateStatus = 'succeeded';
          state.laborWhatIfInputAggregate = payload;
        },
      )
      .addCase(getLaborWhatIfInputAggregateThunk.rejected, (state) => {
        state.laborWhatIfInputAggregateStatus = 'failed';
      })
      .addCase(getLaborWhatIfOutputAggregateThunk.pending, (state) => {
        state.laborWhatIfOutputAggregateStatus = 'loading';
      })
      .addCase(
        getLaborWhatIfOutputAggregateThunk.fulfilled,
        (state, { payload }) => {
          state.laborWhatIfOutputAggregateStatus = 'succeeded';
          state.laborWhatIfOutputAggregate = payload;
        },
      )
      .addCase(getLaborWhatIfOutputAggregateThunk.rejected, (state) => {
        state.laborWhatIfOutputAggregateStatus = 'failed';
      })
      .addCase(resetLaborWhatIfInputThunk.pending, (state) => {
        state.laborWhatIfInputResetStatus = 'loading';
      })
      .addCase(resetLaborWhatIfInputThunk.fulfilled, (state) => {
        state.laborWhatIfInputResetStatus = 'succeeded';
      })
      .addCase(resetLaborWhatIfInputThunk.rejected, (state) => {
        state.laborWhatIfInputResetStatus = 'failed';
      })
      .addCase(calculateLaborWhatIfsThunk.pending, (state) => {
        state.laborWhatIfOutputCalculateStatus = 'loading';
      })
      .addCase(calculateLaborWhatIfsThunk.fulfilled, (state) => {
        state.laborWhatIfOutputCalculateStatus = 'succeeded';
      })
      .addCase(calculateLaborWhatIfsThunk.rejected, (state) => {
        state.laborWhatIfOutputCalculateStatus = 'failed';
      })
      .addCase(getSearchOrderNumberOptionsThunk.pending, (state) => {
        state.searchOrderNumberOptionsStatus = 'loading';
      })
      .addCase(
        getSearchOrderNumberOptionsThunk.fulfilled,
        (state, { payload }) => {
          state.searchOrderNumberOptionsStatus = 'succeeded';
          state.searchOrderNumberOptions = payload;
        },
      )
      .addCase(getSearchOrderNumberOptionsThunk.rejected, (state) => {
        state.searchOrderNumberOptionsStatus = 'failed';
      })
      .addCase(getSearchedSalesOrderMainThunk.pending, (state) => {
        state.searchedSalesOrderMainStatus = 'loading';
      })
      .addCase(
        getSearchedSalesOrderMainThunk.fulfilled,
        (state, { payload }) => {
          state.searchedSalesOrderMainStatus = 'succeeded';
          state.searchedSalesOrderMain = payload;
        },
      )
      .addCase(getSearchedSalesOrderMainThunk.rejected, (state) => {
        state.searchedSalesOrderMainStatus = 'failed';
      })
      .addCase(getSearchedSalesOrderExtraThunk.pending, (state) => {
        state.searchedSalesOrderExtraStatus = 'loading';
      })
      .addCase(
        getSearchedSalesOrderExtraThunk.fulfilled,
        (state, { payload }) => {
          state.searchedSalesOrderExtraStatus = 'succeeded';
          state.searchedSalesOrderExtra = payload;
        },
      )
      .addCase(getSearchedSalesOrderExtraThunk.rejected, (state) => {
        state.searchedSalesOrderExtraStatus = 'failed';
      })
      .addCase(getSearchedSalesOrderLineItemsPreviewThunk.pending, (state) => {
        state.searchedSalesOrderLineItemsPreviewStatus = 'loading';
      })
      .addCase(
        getSearchedSalesOrderLineItemsPreviewThunk.fulfilled,
        (state, { payload }) => {
          state.searchedSalesOrderLineItemsPreviewStatus = 'succeeded';
          state.searchedSalesOrderLineItemsPreview = payload;
        },
      )
      .addCase(getSearchedSalesOrderLineItemsPreviewThunk.rejected, (state) => {
        state.searchedSalesOrderLineItemsPreviewStatus = 'failed';
      })
      .addCase(getSearchedSalesOrderLineItemsDetailThunk.pending, (state) => {
        state.searchedSalesOrderLineItemsDetailStatus = 'loading';
      })
      .addCase(
        getSearchedSalesOrderLineItemsDetailThunk.fulfilled,
        (state, { payload }) => {
          state.searchedSalesOrderLineItemsDetailStatus = 'succeeded';
          state.searchedSalesOrderLineItemsDetail = payload;
        },
      )
      .addCase(getSearchedSalesOrderLineItemsDetailThunk.rejected, (state) => {
        state.searchedSalesOrderLineItemsDetailStatus = 'failed';
      });
  },
});

/**
 * Selector to retrieve status for manual refresh of scheduling tool data
 * @param state
 */
export const selectRefreshRunningStatus = (state: RootState) =>
  state.schedulingTool.schedulingToolRefreshRunning;

/**
 * Selector to determine whether the status retrieval for manual refresh is loading
 * @param state
 */
export const selectLoadingRefreshRunningStatus = (state: RootState) =>
  state.schedulingTool.schedulingToolRefreshRunningStatus === 'loading';

/**
 * Selector to retrieve Producing Location Configs from the store
 * @param state
 */
export const selectProducingLocationConfigs = (state: RootState) =>
  state.schedulingTool.schedulingToolProducingLocationConfigs;

/**
 * Selector to determine whether Producing Location Configs are loading
 * @param state
 */
export const selectLoadingProducingLocationConfigs = (state: RootState) =>
  state.schedulingTool.schedulingToolProducingLocationConfigsStatus ===
  'loading';

/**
 * Selector to retrieve Producing Location Options from the store
 * @param state
 */
export const selectProducingLocationOptions = (state: RootState) =>
  state.schedulingTool.schedulingToolProducingLocationOptions;

/**
 * Selector to retrieve MCell Maps from the store
 * @param state
 */
export const selectMCellMaps = (state: RootState) =>
  state.schedulingTool.schedulingToolMCellMaps;

/**
 * Selector to determine whether MCell Maps are loading
 * @param state
 */
export const selectLoadingMCellMaps = (state: RootState) =>
  state.schedulingTool.schedulingToolMCellMapsStatus === 'loading';

/**
 * Selector to retrieve MCell Guardrails from the store
 * @param state
 */
export const selectMCellGuardrails = (state: RootState) =>
  state.schedulingTool.schedulingToolGuardrails;

/**
 * Selector to determine whether MCell Guardrails are loading
 * @param state
 */
export const selectLoadingMCellGuardrails = (state: RootState) =>
  state.schedulingTool.schedulingToolGuardrailsStatus === 'loading';

/**
 * Selector to retrieve Sales Orders from the store
 * @param state
 */
export const selectSalesOrders = (state: RootState) =>
  state.schedulingTool.schedulingToolSalesOrders;

/**
 * Selector to determine whether Sales Orders are loading
 * @param state
 */
export const selectLoadingSalesOrders = (state: RootState) =>
  state.schedulingTool.schedulingToolSalesOrdersStatus === 'loading';

/**
 * Selector to retrieve Sales Order Line Items from the store
 * @param state
 */
export const selectSalesOrderLineItems = (state: RootState) =>
  state.schedulingTool.schedulingToolSalesOrderLineItems;

/**
 * Selector to determine whether Sales Order Line Items are loading
 * @param state
 */
export const selectLoadingSalesOrderLineItems = (state: RootState) =>
  state.schedulingTool.schedulingToolSalesOrderLineItemsStatus === 'loading';

/**
 * Selector to retrieve Unscheduled Sales Orders from the store
 * @param state
 */
export const selectUnscheduledSalesOrders = (state: RootState) =>
  state.schedulingTool.schedulingToolUnscheduledSalesOrders;

/**
 * Selector to determine whether Unscheduled Sales Orders are loading
 * @param state
 */
export const selectLoadingUnscheduledSalesOrders = (state: RootState) =>
  state.schedulingTool.schedulingToolUnscheduledSalesOrdersStatus === 'loading';

/**
 * Selector to retrieve Unscheduled Sales Order Line Items from the store
 * @param state
 */
export const selectUnscheduledSalesOrderLineItems = (state: RootState) =>
  state.schedulingTool.schedulingToolUnscheduledSalesOrderLineItems;

/**
 * Selector to determine whether Unscheduled Sales Order Line Items are loading
 * @param state
 */
export const selectLoadingUnscheduledSalesOrderLineItems = (state: RootState) =>
  state.schedulingTool.schedulingToolUnscheduledSalesOrderLineItemsStatus ===
  'loading';

/**
 * Selector to retrieve Hard Holds from the store
 * @param state
 */
export const selectHardHolds = (state: RootState) =>
  state.schedulingTool.schedulingToolHardHolds;

/**
 * Selector to determine whether Hard Holds are loading
 * @param state
 */
export const selectLoadingHardHolds = (state: RootState) =>
  state.schedulingTool.schedulingToolHardHoldsStatus === 'loading';

/**
 * Selector to retrieve Settings from the store
 * @param state
 */
export const selectSettings = (state: RootState) =>
  state.schedulingTool.schedulingToolSettings;

/**
 * Selector to determine whether Settings are loading
 * @param state
 */
export const selectLoadingSettings = (state: RootState) =>
  state.schedulingTool.schedulingToolSettingsStatus === 'loading';

/**
 * Selector to select Change Logs from the store
 * @param state
 */
export const selectChangeLogs = (state: RootState) =>
  state.schedulingTool.schedulingToolChangeLogs;

/**
 * Selector to determine whether Change Logs are loading
 * @param state
 */
export const selectLoadingChangeLogs = (state: RootState) =>
  state.schedulingTool.schedulingTooChangeLogsStatus === 'loading';

/**
 * Selector to retrieve Capacity Utilizations from the store
 * @param state
 */
export const selectCapacityUtilizations = (state: RootState) =>
  state.schedulingTool.capacityUtilizationsAggregate.capacityUtilizations;

/**
 * Selector to retrieve Capacity Availables from the store
 * @param state
 */
export const selectCapacityAvailables = (state: RootState) =>
  state.schedulingTool.capacityUtilizationsAggregate.capacityAvailables;

/**
 * Selector to retrieve Capacity Scheduleds from the store
 * @param state
 */
export const selectCapacityScheduleds = (state: RootState) =>
  state.schedulingTool.capacityUtilizationsAggregate.capacityScheduleds;

/**
 * Selector to retrieve Capacity Utilization Units Count from the store
 * @param state
 */
export const selectCapacityUtilizationUnitsCount = (state: RootState) =>
  state.schedulingTool.capacityUtilizationsAggregate
    .capacityUtilizationUnitsCounts;

/**
 * Selector to retrieve Capacity Utilization What Ifs from the store
 * @param state
 */
export const selectCapacityUtilizationWhatIfs = (state: RootState) =>
  state.schedulingTool.capacityUtilizationsAggregate.capacityUtilizationWhatIfs;

/**
 * Selector to retrieve Producing Location Extra Metrics from the store
 * @param state
 */
export const selectProducingLocationExtraMetrics = (state: RootState) =>
  state.schedulingTool.capacityUtilizationsAggregate
    .producingLocationExtraMetrics;

/**
 * Selector to retrieve Full Time Equivalents from the store
 * @param state
 */
export const selectCapacityUtilizationFullTimeEquivalents = (
  state: RootState,
) =>
  state.schedulingTool.capacityUtilizationsAggregate
    .capacityUtilizationFullTimeEquivalents;

/**
 * Selector to retrieve Capacity Utilization Groups from the store
 * @param state
 */
export const selectCapacityUtilizationGroups = (state: RootState) =>
  state.schedulingTool.capacityUtilizationsAggregate.capacityUtilizationGroups;

/**
 * Selector to retrieve Capacity Utilization Producing Locations from the store
 * @param state
 */
export const selectCapacityUtilizationProducingLocations = (state: RootState) =>
  state.schedulingTool.capacityUtilizationsAggregate
    .capacityUtilizationProducingLocations;

/**
 * Selector to determine whether Capacity Utilization Aggregate Data is loading
 * @param state
 */
export const selectLoadingCapacityUtilizationAggregateData = (
  state: RootState,
) => state.schedulingTool.capacityUtilizationsAggregateStatus === 'loading';

/**
 * Selector to retrieve Capacity Utilization Employee Counts
 * @param state
 */
export const selectCapacityUtilizationEmployeeCounts = (state: RootState) =>
  state.schedulingTool.capacityUtilizationEmployeeCounts;

/**
 * Selector to determine whether Capacity Utilization Employee Counts are loading
 * @param state
 */
export const selectLoadingCapacityUtilizationEmployeeCounts = (
  state: RootState,
) => state.schedulingTool.capacityUtilizationEmployeeCountsStatus === 'loading';

/**
 * Selector to retrieve Capacity Utilization PTO Hour Totals
 * @param state
 */
export const selectCapacityUtilizationPtoHoursTotals = (state: RootState) =>
  state.schedulingTool.capacityUtilizationPtoHoursTotals;

/**
 * Selector to determine whether Capacity Utilization Pto Hours are loading
 * @param state
 */
export const selectLoadingCapacityUtilizationPtoHoursTotals = (
  state: RootState,
) => state.schedulingTool.capacityUtilizationPtoHoursTotalsStatus === 'loading';

/**
 * Selector to retrieve Capacity Utilization Shift Hour Totals
 * @param state
 */
export const selectCapacityUtilizationShiftHoursTotals = (state: RootState) =>
  state.schedulingTool.capacityUtilizationShiftHoursTotals;

/**
 * Selector to determine whether Capacity Utilization Shift Hours are loading
 * @param state
 */
export const selectLoadingCapacityUtilizationShiftHoursTotals = (
  state: RootState,
) =>
  state.schedulingTool.capacityUtilizationShiftHoursTotalsStatus === 'loading';

/**
 * Selector to determine the loading status of the Labor What If Input Aggregate
 * @param state
 */
export const selectLoadingLaborWhatIfInputAggregate = (state: RootState) =>
  state.schedulingTool.laborWhatIfInputAggregateStatus === 'loading';

/**
 * Selector to retrieve the Labor What If Input Aggregate data
 * @param state
 */
export const selectLaborWhatIfInputAggregate = (state: RootState) =>
  state.schedulingTool.laborWhatIfInputAggregate;

/**
 * Selector to retrieve the Labor What If Output Aggregate data
 * @param state
 */
export const selectLaborWhatIfOutputAggregate = (state: RootState) =>
  state.schedulingTool.laborWhatIfOutputAggregate;

/**
 * Selector to determine the loading status of the Labor What If Output Aggregate
 * @param state
 */
export const selectLoadingLaborWhatIfOutputAggregate = (state: RootState) =>
  state.schedulingTool.laborWhatIfOutputAggregateStatus === 'loading';

/**
 * Selector to determine the loading status of the Labor What If Input Reset
 * @param state
 */
export const selectLoadingLaborWhatIfInputResetStatus = (state: RootState) =>
  state.schedulingTool.laborWhatIfInputResetStatus === 'loading';

/**
 * Selector to determine the loading status of the Labor What If Output Calculate
 * @param state
 */
export const selectLoadingLaborWhatIfOutputCalculateStatus = (
  state: RootState,
) => state.schedulingTool.laborWhatIfOutputCalculateStatus === 'loading';

/**
 * Selector to retrieve the search order number options from the store
 * @param state
 */
export const selectSearchOrderNumberOptions = (state: RootState) =>
  state.schedulingTool.searchOrderNumberOptions;

/**
 * Selector to determine whether the search order number options are loading
 * @param state
 */
export const selectLoadingSearchOrderNumberOptions = (state: RootState) =>
  state.schedulingTool.searchOrderNumberOptionsStatus === 'loading';

/**
 * Selector to retrieve the searched sales order main info from the store
 * @param state
 */
export const selectSearchedSalesOrderMain = (state: RootState) =>
  state.schedulingTool.searchedSalesOrderMain;

/**
 * Selector to determine whether the searched sales order main info is loading
 * @param state
 */
export const selectLoadingSearchedSalesOrderMain = (state: RootState) =>
  state.schedulingTool.searchedSalesOrderMainStatus === 'loading';

/**
 * Selector to retrieve the searched sales order extra info from the store
 * @param state
 */
export const selectSearchedSalesOrderExtra = (state: RootState) =>
  state.schedulingTool.searchedSalesOrderExtra;

/**
 * Selector to determine whether the searched sales order extra info is loading
 * @param state
 */
export const selectLoadingSearchedSalesOrderExtra = (state: RootState) =>
  state.schedulingTool.searchedSalesOrderExtraStatus === 'loading';

/**
 * Selector to retrieve the searched sales order line items preview from the store
 * @param state
 */
export const selectSearchedSalesOrderLineItemsPreview = (state: RootState) =>
  state.schedulingTool.searchedSalesOrderLineItemsPreview;

/**
 * Selector to determine whether the searched sales order line items preview are loading
 * @param state
 */
export const selectLoadingSearchedSalesOrderLineItemsPreview = (
  state: RootState,
) =>
  state.schedulingTool.searchedSalesOrderLineItemsPreviewStatus === 'loading';

/**
 * Selector to retrieve the searched sales order line items detail from the store
 * @param state
 */
export const selectSearchedSalesOrderLineItemsDetail = (state: RootState) =>
  state.schedulingTool.searchedSalesOrderLineItemsDetail;

/**
 * Selector to determine whether the searched sales order line items detail are loading
 * @param state
 */
export const selectLoadingSearchedSalesOrderLineItemsDetail = (
  state: RootState,
) => state.schedulingTool.searchedSalesOrderLineItemsDetailStatus === 'loading';

export default schedulingToolSlice.reducer;
